import request from '@/utils/request'

// 下载课程资料
export function submitDownload(ids) {
  return request({
    url: 'bm/material/download/'+ids,
    method: 'post',
  })
}

// 获取课程资料列表
export function getDownloadList(query) {
  return request({
    url: '/bm/material/list',
    method: 'get',
    params: query
  })
}

// 查询课程列表列表
export function listCourse(query) {
  return request({
    url: '/bm/course/list-join-user',
    method: 'get',
    params: query
  })
}
// 查询课程列表列表2去掉权限
export function listCoursepc(query) {
  return request({
    url: '/bm/course/wx/list',
    method: 'get',
    params: query,
    headers: { isToken: false }
  })
}

// 查询课程列表列表
export function listUserCourse(query) {
  return request({
    url: '/bm/course/list-by-user',
    method: 'get',
    params: query
  })
}

// 查询教师在教课程列表
export function listTeacherCourse(query) {
  return request({
    url: '/bm/course/list-by-course-teacher',
    method: 'get',
    params: query
  })
}

// 查询课程列表详细
export function getCourse(id) {
  return request({
    url: '/bm/course/' + id,
    method: 'get'
  })
}
// 查询课程列表列表2去掉权限
// 查询课程列表详细2
export function getCoursepc(id) {
  return request({
    url: '/bm/course/wx/' + id,
    method: 'get',
    headers: { isToken: false }
  })
}

// 查询课程列表详细
export function getCourseJoinUser(id) {
  return request({
    url: '/bm/course/user/' + id,
    method: 'get'
  })
}


// 新增课程列表
export function addCourse(data) {
  return request({
    url: '/bm/course',
    method: 'post',
    data: data
  })
}

// 修改课程列表
export function updateCourse(data) {
  return request({
    url: '/bm/course',
    method: 'put',
    data: data
  })
}

// 删除课程列表
export function delCourse(id) {
  return request({
    url: '/bm/course/' + id,
    method: 'delete'
  })
}

// 导出课程列表
export function exportCourse(query) {
  return request({
    url: '/bm/course/export',
    method: 'get',
    params: query
  })
}
