import request from '@/utils/request'

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
        url: '/system/dict/data/wx/type/' + dictType,
        method: 'get',
        headers: {
            isToken: false
        }
    })
}

// 回显数据字典
// export function selectDictLabel(datas, value, dictValue = 'dictValue', dictLabel = 'dictLabel') {
//     var actions = [];
//     Object.keys(datas).some((key) => {
//         if (datas[key][dictValue] == ('' + value)) {
//             actions.push(datas[key][dictLabel]);
//             return true;
//         }
//     })
//     return actions.join('');
// }

// 回显数据字典
export function selectDictLabel(datas, value, dictValue = 'dictValue', dictLabel = 'dictLabel') {
    var actions = [];
    Object.keys(datas).some((key) => {
        if (datas[key][dictValue] == ('' + value)) {
            actions.push(datas[key][dictLabel]);
            return true;
        }
    })
    return actions.join('');
}

export function selectDefaultImg(num) {
    num = num || 0;
    return require('@/assets/image/default-0' + (num % 3 + 1) + '.png');
}

export function defaultAvatar() {
    return require('@/assets/image/default-avatar.png')
}
// 根据参数键名查询参数值
export function getConfigKey(configKey) {
    return request({
        url: '/system/config/wx/configKey/' + configKey,
        method: 'get',
        headers: {
            isToken: false
        }
    })
}
// 根据参数键名查询参数值 电话
export function getConfigKeytel(configKeytel) {
    return request({
        url: '/system/config/wx/configKey/' + configKeytel,
        method: 'get',
        headers: {
            isToken: false
        }
    })
}
// 根据参数键名判断pc 
export function getConfigKeypc(configKeypc) {
    return request({
        url: '/system/config/wx/configKey/' + configKeypc,
        method: 'get',
        headers: {
            isToken: false
        }
    })
}
// 根据参数键名判断iphone
export function getConfigKeyiphone(configKeyiphone) {
    return request({
        url: '/system/config/wx/configKey/' + configKeyiphone,
        method: 'get',
        headers: {
            isToken: false
        }
    })
}