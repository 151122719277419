import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import SvgIcon from 'vue-svgicon'

import {
  defaultAvatar,
  getConfigKey,
  getConfigKeyiphone,
  getConfigKeypc,
  getConfigKeytel,
  getDicts,
  selectDefaultImg,
  selectDictLabel,
} from "./api/data";
// Vue.config.productionTip = false
import echarts from "echarts";
import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$echarts = echarts;
Vue.prototype.$video = Video
Vue.use(SvgIcon, {
  tagName: "svg-icon",
  defaultWidth: "1em",
  defaultHeight: "1em"
});
Vue.use(ElementUI);
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.getConfigKey = getConfigKeytel
Vue.prototype.getConfigKey = getConfigKeypc
Vue.prototype.getConfigKey = getConfigKeyiphone
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.defaultAvatar = defaultAvatar
Vue.prototype.selectDefaultImg = selectDefaultImg
// Vue.prototype.$phoneUrl = "https://cqc.pinxueyun.com/cqc-mweb/#/"
Vue.prototype.$phoneUrl = "https://m.pinxueyun.com/#/"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
