<template>
  <div class="nav-header">
    <div class="nav">
      <!--顶部导航栏-->
      <div class="navigation-wrapper">
        <!--平台logo-->
        <router-link tag="a" to="/">
          <img class="tx-img" src="../assets/image/cqc-logo.png" alt=""/>
        </router-link>
        <div class="navigation-item" :class="{'active':router===item.router}" v-for="item in navigationBar"
             :key="item.router">
          <router-link tag="a" :to="item.router" :class="{'active':router === item.router}">{{
              item.label
            }}
          </router-link>
        </div>
      </div>
      <div style="display: flex;align-items: center">
        <search style="margin-right: 20px"></search>
        <!-- 登录 注册 -->
        <div class="nav-login" v-show="token == false">
          <router-link class="nav-login1" tag="a" to="/user/login">登录</router-link>
          <router-link class="nav-login1 nav-login2" tag="a" to="/user/register">注册
          </router-link>
        </div>
        <nav v-show="token">
          <router-link v-show="isTeacher" class="nav-bm nav-bm2" tag="a" to="/teacherCourse">在教课程</router-link>
          <router-link class="nav-bm nav-bm2" tag="a" to="/notice">平台通知</router-link>
          <router-link class="nav-bm" tag="a" to="/bm/list">我的课程</router-link>
          <router-link v-show="rolesShow" class="nav-bm" tag="a" to="/inspector">检查员中心
          </router-link>
          <span></span>
          <el-dropdown>
            <div class="el-dropdown-link">
              <img class="tx-img2" :src="avatar || defaultAvatar()" alt/>
              <div>{{ info.name || info.user.nickName }}</div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="dropdown-1"
              >
                <router-link tag="a" to="/my">个人资料</router-link>
              </el-dropdown-item
              >
              <el-dropdown-item class="dropdown-1">
                <span @click="logout">退出登录</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import {getToken} from "@/utils/auth";
import {getStudent} from "@/api/bm/student";
import store from "@/store";
import search from '@/components/search/index.vue'

export default {
  components: {
    search
  },
  data() {
    return {
      active: ["active", "", "", ""],
      tx: false,
      login: true,
      avatar: undefined,
      info: {
        user: {
          avatar: "",
        },
      },
      // 检查员
      rolesShow: false,
      // 在教课程
      isTeacher: false,
      sexOptions: [],
      token: false,
      navigationBar: [
        {
          label: '直播课程',
          router: '/'
        },
        {
          label: '点播课程',
          router: '/courseCenter'
        }
      ],
      router: ''
    };
  },
  methods: {
    getInfo() {
      getStudent().then((res) => {
        this.info = res.data;
        this.avatar = res.data.user.avatar;
        this.roles = res.data.params.roles;
        // if (this.roles.indexOf("检查员") > -1) {
        //   this.rolesShow = true;
        // } else {
        //   this.rolesShow = false;
        // }
        this.rolesShow = this.roles.indexOf("检查员") > -1 ? true : false
        this.isTeacher = this.roles.indexOf("教师") > -1 ? true : false
        console.log("获取getInfo", this.roles);
      });
    },

    sexFormat(k) {
      return this.selectDictLabel(this.sexOptions, k);
    },
    logout() {
      console.log("99999");
      store.dispatch("LogOut").then(() => {
        location.reload()
      });
    },
  },
  mounted() {
    this.router = this.$route.path
  },
  created() {
    console.log("1111");
    //  this.getInfo22();
    // this.getSignup();
    this.token = getToken() || false;

    console.log("token", this.token);

    this.getDicts("sys_user_sex").then((res) => {
      this.sexOptions = res.data;
    });
    if (this.token) {
      this.getInfo();
    }
  },
  watch: {
    '$route'(to) {
      this.router = to.path
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-header {
  width: 100%;
  height: 67px;
  background: #fff;
  overflow: hidden;

  .nav {
    @include center;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    line-height: 67px;
    // border-bottom: 1px solid #dcdfe6;
    box-sizing: border-box;
    height: 67px;
    justify-content: space-between;

    .navigation-wrapper {
      @include center;

      .tx-img {
        margin-right: 78px;
      }

      .navigation-item {
        width: 160px;
        font-size: 16px;
        text-align: center;

        ::v-deep.router-link-active {
          &:active, &.active, &:hover {
            color: rgba(30, 112, 234, 0.89) !important;
          }
        }

        &.active {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            bottom: 2px;
            width: 80px;
            height: 2px;
            left: 40px;
            background: rgba(30, 112, 234, 0.89);
          }
        }
      }
    }

    .nav-login {
      // width: 500px;
      height: 67px;
      // border: 1px solid tan;
      float: right;

      .nav-login1 {
        width: 60px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        display: inline-block;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        background: linear-gradient(
                -54deg,
                rgba(30, 112, 234, 0.89),
                rgba(72, 140, 240, 0.89)
        );
        border-radius: 4px;
      }

      .nav-login2 {
        margin-left: 30px;
      }

      // ::v-deep .el-dropdown-menu__item {
      //   list-style: none;
      //   line-height: 36px;
      //   padding: 0 30px;
      //   margin: 0;
      //   font-size: 14px;
      //   color: #606266;
      //   cursor: pointer;
      //   outline: 0;
      // }
    }
  }

  img {
    vertical-align: middle;
    // border-radius: 100%;
  }

  nav {
    display: inline-block;
    float: right;

    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
      position: relative;

      img {
        position: absolute;
        top: 0.8vh;
        left: 0;
      }

      div {
        padding-left: 60px;
      }
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }

    span {
      width: 1px;
      height: 22px;
      background: #aaaaaa;
      margin: 22px 12px 0;
      float: left;
    }

    .tx-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-top: 7px;
      float: left;
    }

    .tx-img2 {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      position: absolute;
      top: -3.2vh;
      left: 0;
    }

    .nav-bm {
      width: 98px;
      height: 38px;
      line-height: 38px;
      background: linear-gradient(
              -54deg,
              rgba(30, 112, 234, 0.89),
              rgba(72, 140, 240, 0.89)
      );
      border-radius: 4px;
      display: inherit;
      float: left;
      color: #fff;
      text-decoration: none;
      text-align: center;
      margin-top: 14px;
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

::v-deep .el-popper[x-placement^="bottom"] {
  margin-top: 12px;
  padding: 0px;
}

::v-deep .el-dropdown-menu__item {
  list-style: none;
  line-height: 30px;
  padding: 5px 30px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: 0;
}
</style>
