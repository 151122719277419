<template>
  <div>
    <div class="container">
      <div class="idx-content">
        <img src="@/assets/image/cqc-bn.jpg" alt="" />
        <!-- 平台课程列表 -->
        <!-- <div class="idx-course-list">
                <div class="idx-course-list-title"><span></span>平台课程列表</div>
              </div> -->
        <!-- 平台课程切换列表 -->
        <div class="idx-course-list">
          <div class="idx-course-listbox">
            <div class="idx-course-list-title">
              <span></span>直播课程列表 <span class="idx-s2"></span>
            </div>
            <div class="idx-course-tab">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <!-- <div class="idx-course-list-title">
                                <span></span>平台课程列表2
                              </div> -->
                <el-tab-pane label="进行中" name="first">
                  <div class="idx-tab-list" v-for="item in list" :key="item.id">
                    <router-link :to="'/signup/?id=' + item.id">
                      <div class="idx-tab-list-1" v-if="item.status == 1">
                        <div class="idx-tab-list-1Left">
                          <img
                            :src="item.imageUrl || selectDefaultImg(item.id)"
                          />
                        </div>
                        <div class="idx-tab-list-1Right">
                          <div class="idx-R-1">
                            <div class="idx-R1-s1">
                              <div
                                class="way-pos type-val-xs0"
                                v-if="item.way == 0"
                              >
                                线上课程
                              </div>
                              <div class="way-pos type-val2-xs1" v-else>
                                线下课程
                              </div>
                            </div>
                            <span class="idx-R1-s2">{{ item.title }} </span>
                          </div>
                          <div class="idx-R-2" v-html="item.content">
                            {{ item.content }}
                          </div>
                          <div class="idx-R-3">
                            <span class="idx-R3-s1"
                              >报名截止时间：{{ item.signAsOfTime }}</span
                            >
                            <span class="idx-R3-s2"
                              >￥{{ item.unitPrice }}</span
                            >
                          </div>
                          <div class="idx-R-4">
                            <el-popover
                              v-show="rolesShow"
                              placement="top-start"
                              title=""
                              width="280"
                              trigger="hover"
                              style="text-align:center;"
                              @mouseover.native="hoverQrImg(item)"
                            >
                              <div style="text-align:center;" id="picture">
                                <div ref="bill">
                                  <p style="font-size: 18px;;">
                                    请使用手机扫一扫
                                  </p>
                                  <p style="font-size: 16px;;">
                                    推荐人代码:{{ jobNumber }}
                                  </p>
                                  <vue-qr
                                    :logoSrc="imageUrl"
                                    :logoScale="30"
                                    :text="InvitationCode"
                                    :size="200"
                                  ></vue-qr>
                                  <p style="font-size: 16px;;">
                                    中国质量认证中心
                                  </p>
                                </div>
                                <!-- <div class="qrcode" style="width:200px;height:200px" ref="qrCodeUrl"></div> -->
                                <div>
                                  <el-button size="mini" @click="savePic"
                                    >下载邀请码</el-button
                                  >
                                  <el-button
                                    size="mini"
                                    @click="copyCourseAddress(item)"
                                    >复制课程链接</el-button
                                  >
                                </div>
                              </div>
                              <span class="share" slot="reference">分享</span>
                              <!-- <i class="el-icon-share" style="float:right" slot="reference"></i> -->
                              <!-- <el-button slot="reference">分享</el-button> -->
                            </el-popover>
                            <span class="comming">
                              进行中
                            </span>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- 已经到底了哦 -->
                  <!--加载中-->
                  <div class="load-ing idx-title" v-show="load">
                    {{ this.loadtext }}
                  </div>
                  <!--                  <div class="idx-title">已经到底了哦</div>-->
                </el-tab-pane>
                <el-tab-pane label="已过期" name="second">
                  <div class="idx-tab-list" v-for="item in list" :key="item.id">
                    <!-- <div class="idx-tab-list-1" v-if="item.status == 0"> -->
                    <router-link :to="'/signup/?id=' + item.id">
                      <div class="idx-tab-list-1" v-if="item.status == 0">
                        <div class="idx-tab-list-1Left">
                          <img :src="item.imageUrl || courseCover" alt="" />
                        </div>
                        <div class="idx-tab-list-1Right">
                          <div class="idx-R-1">
                            <div class="idx-R1-s1">
                              <div
                                class="way-pos type-val-xs0"
                                v-if="item.way == 0"
                              >
                                线上课程
                              </div>
                              <div class="way-pos type-val2-xs1" v-else>
                                线下课程
                              </div>
                            </div>
                            <span class="idx-R1-s2">{{ item.title }}</span>
                          </div>
                          <div class="idx-R-2" v-html="item.content">
                            {{ item.content }}
                          </div>
                          <div class="idx-R-3">
                            <span class="idx-R3-s1"
                              >报名截止时间：{{ item.signAsOfTime }}</span
                            >
                            <span class="idx-R3-s2"
                              >￥{{ item.unitPrice }}</span
                            >
                          </div>
                          <div class="idx-R-4">已过期</div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- 已经到底了哦 -->
                  <!-- <div class="idx-title">已经到底了哦</div> -->
                  <!--加载中-->
                  <div class="load-ing idx-title" v-show="load">
                    {{ this.loadtext }}
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudent } from '@/api/bm/student';
import { listCoursepc } from '@/api/bm/course';
import vueQr from 'vue-qr';
import html2canvas from 'html2canvas';

export default {
  components: {
    vueQr,
  },
  data() {
    return {
      username: undefined,
      rolesShow: false,
      jobNumber: undefined,
      // 二维码logo
      imageUrl: require('../assets/image/cqc_logo.png'),
      courseCover: require('../assets/image/index2.jpg'),
      // 邀请码
      InvitationCode: 'undefined',

      activeName: 'first',
      list: [],
      loading: false,
      finished: false,
      pageNum: 1, //请求第几页
      pageSize: 5, //每页请求的数量
      total: 0, //总共的数据条数
      queryForm: {
        pageSize: 10,
        pageNum: 1,
        isDel: 'N',
      },
      wayOptions: [],
      bmStatusOptions: [],
      //进入当前页面默认请求第一页的数据
      pages: 1,
      //提示
      load: false,
      //提示文字
      loadtext: '加载中',
      token: false,
    };
  },
  created() {
    // this.getDicts("bm_course_way").then((res) => {
    //   this.wayOptions = res.data;
    // });
    // this.getDicts("bm_sign_up_status").then((res) => {
    //   this.bmStatusOptions = res.data;
    // });
    // this.getList();
    // 获取工号
    // getinspectorMy().then((res) => {
    //   console.log("获取工号", res.data.jobNumber);
    //   this.jobNumber = res.data.jobNumber;
    // });
    // this.token = getToken() || false;
    // if (this.token) {
    //   this.getInfo();
    // }
  },
  methods: {
    getInfo() {
      getStudent().then((res) => {
        this.roles = res.data.params.roles;
        this.username = res.data.name;
        if (this.roles.indexOf('检查员') > -1) {
          this.rolesShow = true;
        } else {
          this.rolesShow = false;
        }
        console.log('获取getInfo', this.roles);
      });
    },
    hoverQrImg(item) {
      console.log('课程信息：', this.$store.state.user.phoneUrl);
      let _this = this;
      if (this.rolesShow) {
        _this.InvitationCode = `${this.$store.state.user.phoneUrl}signup/?id=${item.id}&jobNumber=${this.jobNumber}`;
      } else {
        _this.InvitationCode = `${this.$store.state.user.phoneUrl}signup/?id=${item.id}`;
      }
      console.log(this.$refs.bill);
    },
    // creatQrCode() {
    //   new QRCode(this.$refs.qrCodeUrl, {
    //     text: `xxxxxxx`, // 需要转换为二维码的内容
    //     width: 200,
    //     height: 200,
    //     colorDark: '#000000',
    //     colorLight: '#ffffff',
    //     correctLevel: QRCode.CorrectLevel.H
    //   })
    //   //清除已经生成的二维码
    //   // this.$refs.qrcode.innerHTML = ''
    // },
    savePic() {
      //找到canvas标签
      console.log(this.$refs.bill[0]);
      setTimeout(() => {
        html2canvas(this.$refs.bill[0], { width: 400, height: 560 }).then(
          (canvas) => {
            console.log('保存图片', canvas);
            let imageUrl = canvas.toDataURL('image/png'); // 将canvas转成base64图片格式
            console.log('保存图片后的url', imageUrl);
            let a = document.createElement('a');
            //设置a标签的href属性（将canvas变成png图片）
            a.href = imageUrl;
            a.download = '佰科平台课程邀请码';
            a.click();
            this.$message({
              message: '下载成功!',
              type: 'success',
            });
          }
        );
      }, 300);
    },
    // 复制课程地址链接
    copyCourseAddress(item) {
      var url = `${this.$store.state.user.phoneUrl}signup/?id=${item.id}&jobNumber=${this.jobNumber}`;
      var oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message({
        message: '复制成功!',
        type: 'success',
      });
    },
    hodeData() {
      listCoursepc(this.queryForm).then((res) => {
        console.log('请求课程列表成功');
        console.log(res.rows);
        let rows = res.rows; //请求返回当页的列表
        this.total = res.total;
        //

        //判断数据是否为空
        if (res.rows.length != 0) {
          console.log('打印pages');
          console.log(this.queryForm.pageNum);
          if (this.queryForm.pageNum == 1) {
            this.list = res.rows;
            console.log('list长度1', this.list.length);
          } else {
            //上拉加载请求出来的数据和之前的数据做一个合并
            this.list = this.list.concat(res.rows);
          }
        }
        console.log('list长度', this.list.length);
      });

      //
    },
    // 获取列表
    getList() {
      listCoursepc(this.queryForm).then((res) => {
        console.log('请求课程列表成功');
        console.log(res);
        this.list = res.rows;
      });
    },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //跳转页面
    // hrefdetail(e) {
    //   this.$router.push({ path: "/signup" });
    //   console.log(e.currentTarget.id);
    // },
  },
  mounted() {
    this.hodeData();
    //上拉加载
    window.onscroll = () => {
      //滚动条滚动时距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //   console.log(scrollTop);
      //可视化区域的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //				console.log(windowHeight)
      //滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      console.log('滚动条的总高度', scrollHeight);
      //判断滚动条到底部的条件
      //   if (scrollTop + windowHeight == scrollHeight) {
      if (scrollTop + windowHeight > 1000) {
        console.log('到底部了');
        this.queryForm.pageNum++;
        console.log('第', this.queryForm.pageNum);
        console.log('列表数据总条数', this.list.length);
        // console.log(this.queryForm.pageNum);
        //提示出现
        this.load = true;
        if (this.list.length < this.total) {
          this.hodeData();
          console.log(
            '列表数据条数>=总条数，不再触发滚动加载',
            this.list.length
          );
        } else if (this.list.length == this.total) {
          this.loadtext = '已经到底了哦';
          this.load = true;
        } else {
          this.loadtext = '已经到底了哦';
          this.load = true;
        }
      }
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  height: auto;
  background: linear-gradient(
    #dbe7fc 1%,
    #ffffff 20%,
    #ffffff 20%,
    #ffffff 20%,
    #ffffff 39%
  );
  padding-top: 22px;

  .idx-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    // text-align: center;
    img {
      width: 1300px;
      height: 450px;
    }

    .idx-course-list {
      width: 1300px;
      height: auto;
      padding-bottom: 138px;

      .idx-course-listbox {
        margin-top: 72px;
        position: relative;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-start;
        // align-items: center;

        ::v-deep .el-tabs__nav-wrap {
          overflow: hidden;
          margin-bottom: -1px;
          position: relative;
          padding-left: 247px;
        }

        ::v-deep .el-tabs__item {
          padding: 0 20px;
          height: 40px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          line-height: 25px;
          display: inline-block;
          list-style: none;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2c2c2c;
          position: relative;
        }

        ::v-deep .el-tabs__item.is-active {
          color: #409eff;
        }

        ::v-deep .el-tabs__active-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          background-color: #409eff;
          z-index: 1;
          -webkit-transition: -webkit-transform 0.3s
            cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: -webkit-transform 0.3s
            cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          list-style: none;
        }

        .idx-course-list-title {
          font-size: 23px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #28333c;
          position: absolute;
          top: 1px;

          span {
            width: 5px;
            height: 27px;
            background: #2979ea;
            border-radius: 3px;
            float: left;
            margin-right: 10px;
          }

          .idx-s2 {
            width: 1px;
            height: 21px;
            background: #cccccc;
            float: right;
            margin-left: 52px;
          }
        }

        .idx-course-tab {
          ::v-deep .el-tabs__nav-wrap:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #e4e7ed;
            z-index: 0;
          }
          .idx-title {
            width: 100%;
            height: 30px;
            line-height: 30px;
            margin: 0 auto;
            color: #7f7f7f;
            text-align: center;
            margin-top: 90px;
          }

          .idx-tab-list {
            cursor: pointer;
            // .idx-title {
            //   width: 100%;
            //   height: 30px;
            //   line-height: 30px;
            //   margin: 0 auto;
            //   color: #000000;
            //   text-align: center;
            //   margin-top: 50px;
            // }
            .idx-tab-list-1 {
              // border: 1px solid teal;
              height: 180px;
              margin-top: 59px;
              position: relative;

              .idx-tab-list-1Left {
                width: 260px;
                float: left;
                margin-right: 35px;

                img {
                  width: 260px;
                  height: 175px;
                  opacity: 1;
                  border-radius: 12px 12px 6px 6px;
                }
              }

              .idx-tab-list-1Right {
                width: 855px;
                float: left;

                .idx-R-1 {
                  font-size: 18px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #000000;
                  display: flex;

                  .idx-R1-s1 {
                    font-size: 14px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #1264eb;
                    border: 1px solid #1264eb;
                    padding: 0 5px;
                    margin-right: 10px;
                  }
                }

                .idx-R-2 {
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #7f7f7f;
                  margin: 40px 0px;
                  line-height: 25px;
                  height: 52px;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2; /*要显示的行数*/
                }

                .idx-R-3 {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;

                  .idx-R3-s2 {
                    font-size: 26px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #003cff;
                    margin-left: 30px;
                  }
                }

                .idx-R-4 {
                  .share {
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: red;
                    border-radius: 50%;
                    margin-right: 10px;
                    background: url(../assets/image/share.png);
                    text-indent: -9999px;
                  }
                  .con {
                    width: 100px;
                    height: 500px;
                  }
                  .el-popover {
                    width: 100px;
                    height: 100px;
                    background: url(../assets/image/qrCode_bg.png);
                    background-size: 100% 100%;
                    background: red;
                  }

                  // width: 98px;
                  height: 38px;
                  line-height: 38px;
                  text-align: center;

                  border-radius: 4px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #ffffff;
                  position: absolute;
                  top: 13px;
                  right: 0;
                  .comming {
                    width: 63px;
                    display: inline-block;
                    background: linear-gradient(
                      -54deg,
                      rgba(30, 112, 234, 0.89),
                      rgba(72, 140, 240, 0.89)
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
