<template>
  <div class="footer">
    <FooterItem title="友情链接：" :itemList="linkList"></FooterItem>
    <!-- <FooterItem title="社区" :itemList="shequList"></FooterItem> -->
    <!-- <FooterSide></FooterSide> -->
  </div>
</template>

<script>
import FooterItem from "./ChildComponents/Footer/FooterItem.vue";
// import FooterSide from "./ChildComponents/Footer/FooterSide.vue";
// 友情链接：     中国质量认证中心      国家市场监督管理局      中国检验认证集团      中国认监委
export default {
  name: "Footer",
  props: {},
  data() {
    return {
      linkList: [
        {
          text: "中国质量认证中心",
          url: "https://www.cqc.com.cn/www/chinese/index.shtml",
        },
        {
          text: "国家市场监督管理局",
          url: "http://www.samr.gov.cn/",
        },
        {
          text: "中国检验认证集团",
          url: "https://www.ccic.com/",
        },
        {
          text: "国家认证认可监督委员会",
          url: "http://www.cnca.gov.cn/",
        },
      ],
    };
  },
  components: {
    FooterItem,
    // FooterSide,
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  //   padding-bottom: 80px;
  background: #ffffff;
  width: 100%;
  // border: 1px solid tan;
  overflow: hidden;
}
</style>
