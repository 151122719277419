<template>
	<div class="footer">
		<div class="footer-item-box">
			<div class="footer-item">
				<span>{{ title }}</span>
				<a :href="item.url" v-for="item in itemList" :key="item.text" target="_blank">
					{{ item.text }}
				</a>
			</div>
		</div>
		<div class="footer-item-box footer-item-box2">
			<div class="footer-item">
				Copyright © 2020 佰科（广东）信息科技有限公司
				<span style="cursor: pointer;color: #fff;font-size:12px;" @click="toLink">粤ICP备20056514号</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FooterItem',
	props: {
		title: String,
		itemList: Array,
	},
	data() {
		return {};
	},
	methods: {
		toLink() {
			window.open('https://beian.miit.gov.cn/');
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
	.footer-item-box {
		width: 100%;
		height: 55px;
		background: #c8c8c8;
		overflow: hidden;
		font-size: 14px !important;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;

		.footer-item {
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			line-height: 55px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			height: 55px;
			text-align: center;

			span {
				font-size: 14px;
				color: #000;
				line-height: 1;
				margin: 0 0 15px;
				font-weight: 400;
				//   float: left;
			}

			a {
				padding: 0 15px;
				line-height: 2;
				font-size: 14px;
				color: #000;
				text-decoration: none;

				&:hover {
					color: #333;
				}
			}
		}
	}
	.footer-item-box2 {
		background: #535353;
		.footer-item {
			color: #ffffff;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
		}
	}
}
</style>
