import axios from 'axios'
import store from '@/store'
import router from '../router/index.js'
import {
  getToken
} from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import {
  Notification,
  MessageBox,
  Message
} from 'element-ui'
export const domain = process.env.NODE_ENV === "production" ? 'https://www.pinxueyun.com/cqc-baike-web/' : 'http://113.108.126.152:3098/cqc-baike-web/'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/dev-api',
  // baseURL: 'http://113.108.126.152:3098/cqc-api',
  // baseURL: 'https://www.pinxueyun.com/dev-api',
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  // const isToken = config.url.indexOf('/wx/') > -1;
  const isToken = (config.headers || {}).isToken || config.url.indexOf('/wx/') > -1;
  if (!isToken && getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const message = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      MessageBox.confirm(
        '登录状态已过期，您可以继续留在该页面，或者重新登录',
        '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        store.dispatch('LogOut').then(() => {
          // location.reload() // 为了重新实例化vue-router对象 避免bug
          // router.push({name: 'login',})
          router.push({path: '/user/login',})
        })
        // console.log('...');
        // location.href('/user/login')
      })
    } else if (code === 500) {
      let urlstr = location.href;
      let nav_name = "register";
      let nav_name2 = "signup";
      // if (urlstr.indexOf(nav_name) > -1 || urlstr.indexOf(nav_name2) > -1) {
      if (urlstr.indexOf(nav_name) > -1) {
        console.log("res", res)
        //设置localStroage值
        localStorage.setItem('disable', res.data.code);
        Message({
          message: message,
          type: 'error'
        })
        return Promise.reject(new Error(message))
      } else {
        Message({
          message: message,
          type: 'error'
        })

        return Promise.reject(new Error(message))
      }
      // Message({
      //   message: message,
      //   type: 'error'
      // })

      // return Promise.reject(new Error(message))
    } else if (code !== 200) {
      Notification.error({
        title: message
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
