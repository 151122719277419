import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/phone-login',
    method: 'post',
    data: data,
    headers:{isToken:true}
  })
}


// 注册
export function register(data) {
  return request({
    url: '/system/user/register',
    method: 'post',
    data: data
  })
}
// 注册发送验证码 bm/note/wx/send/verify?phone=15538093951&tempCode=SMS_204990574
export function verifyCode(query) {
  return request({
    url: '/bm/note/wx/send/verify',
    method: 'post',
    params: query,
    headers: { isToken: false }
  })
}
// 忘记密码
export function forgetPassword(data) {
  return request({
    url: '/system/user/forget',
    method: 'put',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}
