import request from '@/utils/request'


export function getStudent() {
    return request({
        url: '/bm/student/info',
        method: 'get'
    })
}


export function updateStudent(data) {
    return request({
        url: '/bm/student',
        method: 'put',
        data: data
    })
}

// 查询授课老师
export function signTeacher(id) {
    return request({
        url: '/bm/teacher/wx/by-course-id/' + id,
        method: 'get',
        headers: { isToken: false }
    })
}

// 查询最新学员
export function signStudent(id) {
    return request({
        url: '/bm/signUp/wx/by-course-id/' + id,
        method: 'get',
        headers: {isToken: false}
    })
}

// 查询学生证书
export function getStudentCertificate(params) {
    return request({
        url: '/bm/certificate/front/listByStudent',
        method: 'get',
        params: params
    })
}
