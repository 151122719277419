import request from '@/utils/request'

// 查询检查员分数明细列表
export function listInspectorScoreDetail(query) {
  return request({
    url: '/bm/inspectorScoreDetail/list',
    method: 'get',
    params: query
  })
}
// 查询检查员个人分数明细列表me
export function listInspectorScoreDetailMy(query) {
  return request({
    url: '/bm/inspectorScoreDetail/list/me',
    method: 'get',
    params: query
  })
}
// 获取检查员统计柱状图
export function getstatistics(query) {
  return request({
    url: '/bm/inspectorScoreDetail/statisticsByQuarter',
    method: 'get',
    params: query
  })
}
// 获取检查员统计柱状图me
export function getstatisticsMy(query) {
  return request({
    url: '/bm/inspectorScoreDetail/statisticsByQuarter/me',
    method: 'get',
    params: query
  })
}

// 获取登陆检查员信息详细信息
export function getinspectorMy(query) {
  return request({
    url: '/bm/inspector/me',
    method: 'get',
    params: query
  })
}

// 查询检查员分数统计列表
export function listInsScoreTotal(query) {
  return request({
    url: '/bm/inspectorScoreDetail/totallist',
    method: 'get',
    params: query
  })
}

// 查询检查员分数明细详细
export function getInspectorScoreDetail(id) {
  return request({
    url: '/bm/inspectorScoreDetail/' + id,
    method: 'get'
  })
}



// 新增检查员分数明细
export function addInspectorScoreDetail(data) {
  return request({
    url: '/bm/inspectorScoreDetail/save',
    method: 'post',
    data: data
  })
}

// 修改检查员分数明细
export function updateInspectorScoreDetail(data) {
  return request({
    url: '/bm/inspectorScoreDetail',
    method: 'put',
    data: data
  })
}

// 删除检查员分数明细
export function delInspectorScoreDetail(id) {
  return request({
    url: '/bm/inspectorScoreDetail/' + id,
    method: 'delete'
  })
}

// 导出检查员分数明细
export function exportInspectorScoreDetail(query) {
  return request({
    url: '/bm/inspectorScoreDetail/export',
    method: 'get',
    params: query
  })
}

// 导出检查员分数明细
export function exportInsScoreTotal(query) {
  return request({
    url: '/bm/inspectorScoreDetail/totalexport',
    method: 'get',
    params: query
  })
}