<template>
  <div class="search" :class="{'open':open}">
    <input type="search" placeholder="输入课程名称查询" class="search-box"/>
    <span class="search-button" @click="searchChange">
      <span class="search-icon"></span>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false
    }
  },
  methods: {
    searchChange() {
      this.open = !this.open
      if (!this.open) {
        const searchInput = document.getElementsByClassName('search-box')[0]
        this.$router.push({
          path: '/search',
          query: {
            keyword: searchInput.value,
            time: new Date().getTime()
          }
        })
      }
    }
  },
  mounted() {
    let searchInput = document.querySelector('.search-box');
    document.addEventListener('click', (e) => {
      console.log(e.target.className)
      if (~e.target.className.indexOf('search')) {
        if (!this.open) return
        this.open = true
        searchInput.focus();
      } else {
        this.open = false
      }
    })
  },
  watch: {
    open(val) {
      if (!val) {
        const searchInput = document.getElementsByClassName('search-box')[0]
        searchInput.value = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
$background-color: #ffffff;
$search-bg-color: #F8F8F8;
$icon-color: #848484;
$transition: all .5s ease;
* {
  box-sizing: border-box;
}

body {
  background: $background-color;
}

.search {
  width: 38px;
  height: 38px;
  background-color: $search-bg-color;
  position: relative;
  overflow: hidden;
  transition: $transition;
  margin: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  //&:before {
  //  content: '';
  //  display: block;
  //  width: 3px;
  //  height: 100%;
  //  position: relative;
  //  background-color: $icon-color;
  //  transition: $transition;
  //}

  &.open {
    width: 152px;
    border: 1px solid #E1E1E1;
    border-radius: 4px;

    &:before {
      height: 60px;
      margin: 20px 0 20px 30px;
      position: absolute;
    }
  }
}

.search-box {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
  background: transparent;
  color: #848484;
  padding: 20px 20px 20px 8px;
  font-size: 13px;

  &:focus {
    outline: none;
  }
}

.search-button {
  width: 38px;
  height: 100%;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  //padding: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.search-icon {
  width: 20px;
  height: 20px;
  border-radius: 40px;
  border: 3px solid $icon-color;
  display: block;
  position: relative;
  margin-left: 5px;
  transition: $transition;

  &:before {
    content: '';
    width: 3px;
    height: 7.5px;
    position: absolute;
    right: -4px;
    top: 12px;
    display: block;
    background-color: $icon-color;
    transform: rotate(-45deg);
    transition: $transition;
  }

  &:after {
    content: '';
    width: 3px;
    height: 15px;
    position: absolute;
    right: -8px;
    top: 12px;
    display: block;
    background-color: $icon-color;
    transform: rotate(-45deg);
    transition: $transition;
  }

  .open & {
    margin: 0;
    width: 30px;
    height: 30px;
    border-radius: 60px;

    &:before {
      transform: rotate(52deg);
      right: 10px;
      top: 10px;
      height: 10px;
    }

    &:after {
      transform: rotate(-230deg);
      right: 10px;
      top: 5px;
      height: 10px;
    }
  }
}
</style>
