import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import {
    getToken
} from '@/utils/auth'

// const About = () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
// const Component = () => import(/* webpackChunkName: "about" */ '@/views/Component.vue')

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta: {
        title: '佰科在线培训平台',
        // requiresAuth: true
    }
},
{
    path: '/user',
    name: 'LoginRegister',
    component: () => import('@/views/user/login-register.vue'),
    children: [{
        path: 'login',
        name: 'login',
        component: () => import('@/views/user/login.vue'),
    },
    {
        path: 'register',
        component: () => import('@/views/user/register.vue'),
    }
    ]
},
{
    path: '/forgetPassword',
    name: 'forgetPassword',
    meta: {
        title: '忘记密码',
        // requiresAuth: true
    },
    component: () => import('@/views/forgetPassword/forgetPassword.vue')
},
{
    path: '/bm/list',
    name: 'signClass',
    redirect: '/bm/list/live',
    meta: {
        title: '我的课程',
        requiresAuth: true
    },
    component: () => import('@/views/signClass/index.vue'),
    children: [
        {
            path: 'live',
            name: 'live',
            component: () => import('@/views/signClass/live/index.vue')
        },
        {
            path: 'recorded',
            name: 'recorded',
            component: () => import('@/views/signClass/recorded/index.vue')
        },
        {
            path: 'certificate',
            name: 'certificate',
            component: () => import('@/views/signClass/certificate/index.vue')
        }
    ]
},
{
    path: '/signup',
    name: 'signup',
    meta: {
        title: '课程详情',
        // requiresAuth: true   // 要求验证的页面,在此情况下其子页面也会被验证.
    },
    component: () => import('@/views/classDetail/classDetail.vue')
},
{
    path: '/teacherCourse',
    name: 'teacherCourse',
    meta: {
        title: '在线课程',
        requiresAuth: true   // 要求验证的页面,在此情况下其子页面也会被验证.
    },
    component: () => import('@/views/teacherCourse/teacherCourse.vue')
},
{
    path: '/notice',
    name: 'notice',
    meta: {
        title: '平台通知',
        // requiresAuth: true   // 要求验证的页面,在此情况下其子页面也会被验证.
    },
    component: () => import('@/views/notice/notice.vue')
},
{
    path: '/noticeDetail',
    name: 'noticeDetail',
    meta: {
        title: '平台通知',
        // requiresAuth: true   // 要求验证的页面,在此情况下其子页面也会被验证.
    },
    component: () => import('@/views/noticeDetail/noticeDetail.vue')
},
{
    path: '/signProgress',
    name: 'signProgress',
    meta: {
        title: '报名进度',
        requiresAuth: true
    },
    component: () => import('@/views/signProgress/signProgress.vue')
},
{
    // 检查员中心
    path: '/inspector',
    name: 'inspector',
    meta: {
        title: '检查员中心',
        requiresAuth: true
    },
    component: () => import('@/views/inspector/inspector.vue')
},
{
    path: '/my',
    name: 'my',
    meta: {
        title: '个人资料',
        requiresAuth: true
    },
    component: () => import('@/views/my/my.vue')
},
{
    path: '/modification',
    name: 'modification',
    meta: {
        title: '个人资料修改',
        requiresAuth: true
    },
    component: () => import('@/views/modification/modification.vue')
},
{
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
},
{
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
},
{
    path: '/courseCenter',
    name: '/courseCenter',
    component: () => import('@/views/courseCenter/index.vue')
},
{
    path: '/search',
    name: '/search',
    component: () => import('@/views/search/index.vue')
}
]

const router = new VueRouter({
    mode: 'hash',
    routes
})


router.beforeEach((to, from, next) => {
    console.log("跳转路由参数", to);
    if (to.matched.some(record => record.meta.requiresAuth)) { // 哪些需要验证
        if (!getToken()) { // token存在条件
            next({
                path: '/user/login', // 验证失败要跳转的页面
                query: {
                    redirect: to.fullPath // 要传的参数
                }
            })
        } else {
            next()
        }
    } else {
        next() // 确保一定要调用 next()
    }
})
export default router
