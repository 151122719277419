<template>
  <!-- 在线客服按钮 -->
  <div class="fixed">
    <ul>
      <li>
        <div class="libox" @click="toggle()">
          <img class="kf-img" src="@/assets/image/kf.png" alt="" />
          <p>联系客服</p>
        </div>
        <div class="kf-box" v-show="isShow">
          <div class="kf-box-1">
            联系客服<img
              class="kf-img"
              @click="close()"
              src="@/assets/image/gb.png"
              alt=""
            />
          </div>
          <div class="kf-box-2">
            <div class="kf-box-time">{{ nowtime }}</div>
            <!-- <div class="kf-box-title">客服电话：95511</div> -->
            <div class="kf-box-3">
              <div class="kf-box-title kf-box-title2">常见问题</div>
              <div class="kf-box-3box">
                <div
                  class="kf-box-3box-list"
                  v-for="item in list"
                  :key="item.id"
                  :data-itemid="item.id"
                  @click="dialogVisiblebtn($event)"
                >
                  {{ item.question }}
                </div>
                <!-- 问题反馈 -->
                <el-dialog
                  title=""
                  :visible.sync="dialogVisible"
                  width="70%"
                  :append-to-body="true"
                  :before-close="handleClose"
                  :close-on-click-modal="false"
                >
                  <div class="dialog-title">{{ listxq.question }}</div>
                  <div class="dialog-content" v-html="listxq.answer">
                    {{ listxq.answer }}
                  </div>
                </el-dialog>
              </div>
            </div>
            <!-- <a
              class="kf-box-title kf-box-title3"
              href="tencent://message/?uin=1234567&Site=Sambow&Menu=yes"
              >点击咨询在线客服</a
            > -->
            <!-- <a class="kf-box-title kf-box-title3"
              href="tencent://message/?uin=1077321622&Site=sc.chinaz.com&Menu=yes"
              >tencent咨询</a
            > -->
            <!-- href="http://wpa.qq.com/msgrd?v=3&amp;uin=212121&amp;site=qq&amp;menu=yes" -->
            <a
              class="kf-box-title kf-box-title3"
              :href="
                'http://wpa.qq.com/msgrd?v=3&amp;uin=' +
                qqkefu +
                '&amp;site=qq&amp;menu=yes'
              "
              target="_blank"
              >在线客服</a
            >
            <div class="kf-box-title">在线客服电话：{{ tel }}</div>
          </div>
          <!-- <div class="kf-box-2">{{nowtime}}</div> -->
        </div>
      </li>
      <!-- <li class="fd" @click="backTop()" v-if="showGoTop"> -->
      <li class="fd" @click="backTop()">
        <a href="javascript:;"></a>
        <img class="kf-img" src="@/assets/image/fd.png" alt="" />
      </li>
    </ul>
    <!-- <a href="javascript:;"></a> -->
  </div>
</template>

<script>
import { getConfigKey, getConfigKeytel } from "@/api/data";
import { answers, getanswersxq } from "@/api/bm/answers";

export default {
  data() {
    return {
      dialogVisible: false,
      activeName: "1",
      nowtime: "",
      isShow: false,
      // showGoTop: true,
      // 返回顶部
      currentTop: 0,
      showGoTop: false,
      list: [],
      listxq: "",
      qqkefu: undefined,
      tel: undefined,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },
  methods: {
    //获取客服
    getkfu() {
      getConfigKey("qqkefu").then((response) => {
        this.qqkefu = response.msg;
        // console.log("客服qq", response);
      });
      getConfigKeytel("tel").then((response) => {
        this.tel = response.msg;
        // console.log("电话tel", response);
      });
    },
    getTime() {
      this.nowtime = new Date().toLocaleString();
      // console.log(new Date().toLocaleString());
    },
    toggle() {
      this.isShow = !this.isShow;
    },
    close() {
      this.isShow = false;
    },
    // 获取问题列表
    getanswersList() {
      answers().then((res) => {
        console.log("请求在线客服成功666", res.rows);
        console.log(res);
        this.list = res.rows;
      });
    },
    // 获取问题列表
    // getanswersListxq() {
    //   getanswersxq(2).then((res) => {
    //     console.log("请求在线客服成功666", res.data);
    //     console.log(res);
    //     this.listxq = res.data;
    //   });
    // },
    handleClose(done) {
      done();
      this.dialogVisible = false;
      this.dialogVisible2 = false;
      // this.$confirm("确认关闭？")
      //   .then(() => {
      //     done();
      //   })
      //   .catch(() => {});
    },
    // 问题详情弹框
    dialogVisiblebtn: function (e) {
      this.dialogVisible = true;
      const answerid = e.target.dataset.itemid;
      //   console.log(e.target.dataset.itemid); //123
      console.log("打印eeeeeeeeeeee", e.target.dataset.itemid);
      getanswersxq(answerid).then((res) => {
        console.log("请求在线客服成功666", res.data);
        console.log(res);
        this.listxq = res.data;
      });
    },
    //返回顶部
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        //  this.isShowfd = true;
        if (top <= 0) {
          clearInterval(timeTop);
          this.isShowfd = false;
        }
        this.isShowfd = true;
      }, 10);
    },
    //

    windowScroll() {
      if (document.documentElement.scrollTop || document.body.scrollTop > 100) {
        // console.log("scrollTopp",document.documentElement.scrollTop || document.body.scrollTop)
        this.showGoTop = true;
      } else {
        this.showGoTop = false;
      }
    },
  },
  created() {
    this.getTime();
    this.getkfu();
    this.getanswersList();
  },
};
</script>

<style scoped lang="scss">
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #cccccc;
}
div {
  box-sizing: border-box;
}
.container
  .idx-content
  .idx-course-list
  .idx-course-listbox[data-v-fae5bece]
  .el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -1px;
  position: relative;
  padding-left: 247px;
  z-index: 0;
}
.fixed {
  position: fixed;
  right: 0px;
  bottom: 110px;
  ul li {
    width: 65px;
    height: 65px;
    padding: 10px 5px;
    margin-bottom: 3px;
    cursor: pointer;
    background: linear-gradient(
      -54deg,
      rgba(30, 112, 234, 0.89),
      rgba(72, 140, 240, 0.89)
    );
    border-radius: 4px;
    position: relative;
    .kf-img {
      width: 50px;
      height: 50px;
      margin-left: 9px;
    }
    p {
      color: #ffffff;
      font-size: 14px;
      text-align: center;
    }
    ::v-deep .kf-box {
      width: 500px;
      height: 750px;
      border-radius: 10px;
      background: #ffffff;
      border: 1px solid #cccccc;
      overflow: hidden;
      position: absolute;
      top: -520px;
      right: 80px;
      z-index: 9999999999;
      .kf-box-1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        color: #ffffff;
        font-size: 18px;
        text-indent: 10px;
        background: #0074ff;
        position: relative;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 14px;
          right: 17px;
        }
      }
      .kf-box-2 {
        width: 90%;
        height: 600px;
        margin: 0 auto;
        // border: 1px solid #cccccc;
        .kf-box-time {
          width: 100%;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }
        .kf-box-title {
          width: 100%;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border: 1px solid #ddd;
          color: #000;
          font-size: 20px;
          font-weight: bold;
          border-radius: 10px;
          margin-top: 10px;
        }
        .kf-box-3 {
          width: 100%;
          height: 400px;
          // overflow-y: auto;
          margin: 0 auto;
          margin-top: 10px;
          border-radius: 10px;
          border: 1px solid #ddd;
          .kf-box-title2 {
            border: none;
            border-radius: 0px;
            border-bottom: 1px solid #dddddd;
          }
          .kf-box-3box {
            width: 100%;
            height: 320px;
            overflow-y: auto;
            .kf-box-3box-list {
              width: 100%;
              height: 45px;
              line-height: 45px;
              text-align: left;
              padding-left: 10px;
              border-bottom: 1px solid #dddddd;
            }
            ::v-deep .el-dialog__body {
              .dialog-title {
                color: #000;
                font-size: 20px;
              }
            }
          }

          ::v-deep .el-collapse-item__header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 48px;
            line-height: 48px;
            background-color: #fff;
            color: #303133;
            cursor: pointer;
            border-bottom: 1px solid #ebeef5;
            font-size: 13px;
            font-weight: 500;
            -webkit-transition: border-bottom-color 0.3s;
            transition: border-bottom-color 0.3s;
            outline: 0;
            text-indent: 10px;
          }
          ::v-deep .el-collapse-item__content {
            padding-bottom: 25px;
            font-size: 13px;
            color: #303133;
            line-height: 1.769230769230769;
            padding: 10px;
            padding-bottom: 20px;
          }
        }
        .kf-box-title3 {
          margin-top: 20px;
          display: inline-block;
        }
      }
    }
  }
  .fd {
    width: 65px;
    height: 65px;
    padding: 5px 5px;
    background: none;
  }
}
// 弹框
::v-deep .el-dialog {
  .dialog-content {
    padding-bottom: 100px;
    height: 500px;
    overflow-y: auto;
    line-height: 30px;
    font-size: 16px;
    text-align: justify;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dialog-title {
    color: #000;
    font-size: 20px;
    margin-bottom: 15px;
  }
}
</style>
