import request from '@/utils/request'
// 查询在线客服问题列表
export function answers(query) {
    return request({
        url: '/bm/answers/wx/list',
        method: 'get',
        params: query,
        headers: { isToken: false }
    })
}
// 查询在线客服问题详情页
export function getanswersxq(id) {
    return request({
        url: '/bm/answers/wx/' + id,
        method: 'get',
        headers: { isToken: false }
    })
}
// export function answers(query) {
//     return request({
//         url: '/bm/answers/wx/{id}',
//         method: 'get',
//         params: query,
//         headers: { isToken: false }
//     })
// }