import {login, logout, getInfo} from '@/api/login'
import {getToken, setToken, removeToken} from '@/utils/auth'
import {getinspectorMy} from "@/api/bm/inspectorScoreDetail";
import {getStudent} from "@/api/bm/student";
import {getConfigKeyiphone} from "@/api/data";

const user = {
    state: {
        token: getToken(),
        name: '',
        avatar: '',
        roles: [],
        permissions: [],
        user: {},
        phoneUrl: '',
        jobNumber: '',
    },

    mutations: {
        SET_JOBNUMBER: (state, jobNumber) => {
            state.jobNumber = jobNumber
        },
        SET_PHONEURL: (state, phoneUrl) => {
            state.phoneUrl = phoneUrl
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USER: (state, {username, password}) => {
            state.user = {username: username, password: password};
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        }
    },

    actions: {
        setjobNumber({commit}, jobNumber) {
            commit('SET_JOBNUMBER', jobNumber)
        },
        setPhoneUrl({commit}, phoneUrl) {
            commit('SET_PHONEURL', phoneUrl)
        },
        // 登录
        Login({commit}, userInfo) {
            const username = userInfo.username.trim()
            const password = userInfo.password
            const code = userInfo.code
            const uuid = userInfo.uuid
            return new Promise((resolve, reject) => {
                login(username, password, code, uuid).then(res => {
                    setToken(res.token)
                    commit('SET_TOKEN', res.token)
                    commit('SET_USER', userInfo)

                    getStudent().then((res) => {
                        console.log("个人信息：", res);
                        getConfigKeyiphone("iphoneUrl").then((response) => {
                            this.$store.dispatch("setPhoneUrl", response.msg);
                        });
                        let roles = res.data.params.roles;
                        if (roles.indexOf("检查员") > -1) {
                            getinspectorMy().then((res) => {
                                console.log("获取工号", res);
                                if (res.code == 200 && res.data) {
                                    localStorage.setItem('myJobNumber', res.data.jobNumber)
                                    localStorage.setItem('inspectorId', res.data.id)
                                }
                                resolve()
                            });
                        } else {
                            resolve()
                        }
                    });
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({commit, state}) {
            return new Promise((resolve, reject) => {
                getInfo(state.token).then(res => {
                    const user = res.user
                    const avatar = user.avatar == "" ? require("@/assets/image/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
                    if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                        commit('SET_ROLES', res.roles)
                        commit('SET_PERMISSIONS', res.permissions)
                    } else {
                        commit('SET_ROLES', ['ROLE_DEFAULT'])
                    }
                    commit('SET_NAME', user.userName)
                    commit('SET_AVATAR', avatar)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 退出系统
        LogOut({commit, state}) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                    commit('SET_PERMISSIONS', [])
                    commit('SET_PHONEURL', '')
                    commit('SET_JOBNUMBER', '')
                    localStorage.clear()
                    removeToken()
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut({commit}) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                removeToken()
                resolve()
            })
        }
    }
}


export default user
