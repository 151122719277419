<template>
  <div id="app" :class="navShow?'is-show':''">
    <Nav class="header" v-if="navShow"></Nav>
    <router-view class="section" :class="navShow?'is-main':''"/>
    <Online v-if="navShow"></Online>
    <Footer class="footer" v-if="navShow"></Footer>
  </div>
</template>

<script>
import Nav from "@/views/Nav.vue";
import Footer from "@/views/Footer.vue";
import Online from "@/views/online/Online.vue";
import {getConfigKeypc, getConfigKeyiphone} from "@/api/data";
// import { getConfigKey } from "./api/data";
export default {
  components: {
    Nav,
    Footer,
    Online
  },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      show: false,
      list: [],
      listxq: "",
      pcUrl: undefined,
      iphoneUrl: undefined,
      isRouterAlive: true, //控制视图是否显示的变量
      navShow: false
    };
  },
  mounted() {
    this.getConfigKey("restrict.user.login").then((res) => {
      // console.log("登录状态8889999999999999999999999",res)
      let roles = "," + res.msg + ",";
      for (let i = 0; i < this.$store.getters.roles.length; i++) {
        if (roles.indexOf("," + this.$store.getters.roles.length[i] + ",") > -1) {
          this.$store.dispatch("LogOut").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        }
      }
    });
    this.judgeRoute(this.$route)
  },

  created() {
    getConfigKeyiphone("iphoneUrl").then((response) => {
      this.$store.dispatch("setPhoneUrl", response.msg)
    });
    let href_location = window.location.href
    // console.log("当前url:",window.location.href);
    // 判断pc iphone
    if(process.env.NODE_ENV === 'production' && /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
      console.log("移动端啦");
      // let href_location = window.location.href
      getConfigKeyiphone("iphoneUrl").then((response) => {
        this.iphoneUrl = response.msg;
        var new_href = href_location.split("#/");
        console.log("phone截取", new_href);
        this.phoneUrl = `${response.msg}${new_href[1]}`
        console.log("phone新的url", this.phoneUrl);
        window.open(this.phoneUrl, "_self");
        // if(href_location.indexOf("jobNumber") !== -1){
        //   var new_href = href_location.split("#/");
        //   console.log("phone截取",new_href);
        //   this.phoneUrl = `${response.msg}${new_href[1]}`
        //   console.log("phone新的url",this.phoneUrl);
        //   window.open(this.phoneUrl, "_self");
        // }else{
        //   console.log("新的url：",this.iphoneUrl);
        //   console.log("手机端原url：", href_location);
        //   window.open(href_location, "_self");
        // }
      });
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
    // 判断当前路由
    judgeRoute(to) {
      setTimeout(() => {
        if (to.path === '/user/login' || to.path === '/user/register' || to.path === '/forgetPassword') {
          this.navShow = false
        } else {
          this.navShow = true
        }
      })
    }
  },
  watch: {
    '$route'(to) {
      this.judgeRoute(to)
    }
  }
};
</script>

<style lang="scss">
@import url("./assets/css/reset.css");
@import url("./assets/font_icon/iconfont.css");

.el-popper {
  .infomsg {
    text-align: center;
    display: flex;
    justify-content: space-around;
    z-index: 99999999;
    height: 380px;
    margin-bottom: 10px;

    .ownInfo {
      background: url(./assets/image/qrCode_bg.png) no-repeat;
      background-size: 100%;
      width: 330px;
      height: 380px;
      // box-shadow:  rgba(0,0,0,.1) 0px 0px 50px;
      border: 1px solid #299ee8;
      border-radius: 20px;
      text-align: center;
      // z-index: 99999999;
      // padding-top: 90px;
      .messege {
        padding: 18px 0 20px;
        font-size: 18px;
        line-height: 1;
        color: #fff;
      }

      .username {
        width: 100%;
        text-align: right;
        padding-right: 25px;
        color: #fff;
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.5;
      }

      .username2 {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

#app {
  width: 100%;
  height: 100vh;

  &.is-show {
    padding-top: 68px;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.1);
  }

  .section {
    //min-height: calc(100% - 0);
    min-height: 100%;

    &.is-main {
      min-height: calc(100% - 108px);
      background: #F5F5F5;
    }
  }
}

a {
  text-decoration: none;
  color: #000;
}

// 去掉浏览器默认背景
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #cccccc;
}

div {
  box-sizing: border-box;
}

.container
.idx-content
.idx-course-list
.idx-course-listbox[data-v-fae5bece]
.el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -1px;
  position: relative;
  padding-left: 247px;
  z-index: 0;
}

.fixed {
  position: fixed;
  right: 0px;
  bottom: 110px;

  ul li {
    width: 65px;
    height: 65px;
    padding: 10px 5px;
    margin-bottom: 3px;
    cursor: pointer;
    background: linear-gradient(
            -54deg,
            rgba(30, 112, 234, 0.89),
            rgba(72, 140, 240, 0.89)
    );
    border-radius: 4px;
    position: relative;

    .kf-img {
      width: 50px;
      height: 50px;
      margin-left: 9px;
    }

    p {
      color: #ffffff;
      font-size: 14px;
      text-align: center;
    }

    ::v-deep .kf-box {
      width: 500px;
      height: 750px;
      border-radius: 10px;
      background: #ffffff;
      border: 1px solid #cccccc;
      overflow: hidden;
      position: absolute;
      top: -520px;
      right: 80px;
      z-index: 9999999999;

      .kf-box-1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        color: #ffffff;
        font-size: 18px;
        text-indent: 10px;
        background: #0074ff;
        position: relative;

        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 14px;
          right: 17px;
        }
      }

      .kf-box-2 {
        width: 90%;
        height: 600px;
        margin: 0 auto;
        // border: 1px solid #cccccc;
        .kf-box-time {
          width: 100%;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }

        .kf-box-title {
          width: 100%;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border: 1px solid #ddd;
          color: #000;
          font-size: 20px;
          font-weight: bold;
          border-radius: 10px;
        }

        .kf-box-3 {
          width: 100%;
          height: 400px;
          // overflow-y: auto;
          margin: 0 auto;
          margin-top: 10px;
          border-radius: 10px;
          border: 1px solid #ddd;

          .kf-box-title2 {
            border: none;
          }

          .kf-box-3box {
            width: 100%;
            height: 320px;
            overflow-y: auto;
          }

          ::v-deep .el-collapse-item__header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 48px;
            line-height: 48px;
            background-color: #fff;
            color: #303133;
            cursor: pointer;
            border-bottom: 1px solid #ebeef5;
            font-size: 13px;
            font-weight: 500;
            -webkit-transition: border-bottom-color 0.3s;
            transition: border-bottom-color 0.3s;
            outline: 0;
            text-indent: 10px;
          }

          ::v-deep .el-collapse-item__content {
            padding-bottom: 25px;
            font-size: 13px;
            color: #303133;
            line-height: 1.769230769230769;
            padding: 10px;
            padding-bottom: 20px;
          }
        }

        .kf-box-title3 {
          margin-top: 20px;
          display: inline-block;
        }
      }
    }
  }

  .fd {
    width: 65px;
    height: 65px;
    padding: 5px 5px;
    background: none;
  }
}
</style>
